import { ReactNode } from 'react';

interface Props {
  children?: ReactNode | ReactNode[] | string;
}
const Container = ({ children }: Props) => {
  return (
    <div className="sm:px-2 md:px-8 lg:px-16 py-8">
      <div className="container ml-auto mr-auto">{children}</div>
    </div>
  );
};

export default Container;
