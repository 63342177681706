import React from 'react';

import Logo from './components/logo';
import LanguageSwitcher from './components/language-switcher';
import LoginSignUp from './components/login-signup';
import { useSelector } from 'react-redux';

const NavBar = () => {
  const { is_logged_in } = useSelector((state: any) => state.user);

  return (
    <div className="navbar bg-base-100 sm:px-2 lg:px-16">
      <div className="navbar-start">
        <Logo />
      </div>
      <div className="navbar-end">
        <LanguageSwitcher />
        {!is_logged_in && <LoginSignUp />}
      </div>
    </div>
  );
};
export default NavBar;
