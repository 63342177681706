import React from 'react';

const Loader = () => {
  return (
    <div className="fixed inset-0 left-0 bg-base-100 bg-opacity-90 z-10 h-full w-full flex items-center justify-center">
      <div className="flex items-center">
        <span className="loading loading-ring loading-lg text-primary"></span>
      </div>
    </div>
  );
};
export default Loader;
