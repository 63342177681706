import React from 'react';
import Image from 'src/components/basic/image';
import { Trans } from 'react-i18next';

const Logo = () => {
  return (
    <div className="hidden lg:flex">
      <a className="flex items-center" href="/">
        <Image
          className="w-16 p-1 mr-2 rounded-full"
          src="assets/icons/site-logo.png"
        />
        <span>
          <Trans i18nKey="site_title"></Trans>
        </span>
      </a>
    </div>
  );
};

export default Logo;
