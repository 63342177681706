import config from './config';
import { request } from './network';

export async function getSurvey(
  survey_id: string,
  lang: string,
  depth: string,
) {
  const response = await request(
    {
      method: 'GET',
      url: `${config.surveyURL}/v1/surveys/${survey_id}?lang=${lang}&depth=${depth}`,
    },
    false,
  );

  return response.data;
}

export async function getResponses(
  survey_id: string,
  user_id: string,
  status: string,
) {
  const user_part = user_id ? `&user=${user_id}` : '';
  const response = await request(
    {
      method: 'GET',
      url: `${config.surveyURL}/v1/responses?survey=${survey_id}${user_part}&status=${status}`,
    },
    false,
  );

  return response.data;
}

export async function createResponse(survey_id: string, user_id: string) {
  console.log('========', user_id);
  const response = await request(
    {
      method: 'POST',
      url: `${config.surveyURL}/v1/surveys/${survey_id}/responses`,
      data: {},
    },
    false,
  );

  return response.data;
}

export async function updateResponse(response_id: string, action: string) {
  const response = await request(
    {
      method: 'PUT',
      url: `${config.surveyURL}/v1/responses/${response_id}?action=${action}`,
      data: {},
    },
    false,
  );

  return response.data;
}

export async function updateAnswer(
  response_id: string,
  question_id: string,
  answer_text: string,
) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.surveyURL}/v1/responses/${response_id}/answers`,
      data: {
        questionId: question_id,
        answerText: answer_text,
      },
    },
    false,
  );

  return response.data;
}
