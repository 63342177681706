import React from 'react';
import { useOutlet } from 'react-router-dom';
import SideMenu from '../side-menu';

export default function AuthLayout() {
  const outlet = useOutlet();

  return (
    <div className="mx-auto max-w-screen flex flex-row px-4 py-4 md:px-8 bg-white">
      <div className="flex-none py-2">
        <SideMenu />
      </div>
      <div className="flex-1 overflow-y-auto">{outlet}</div>
    </div>
  );
}
