import { ThunkDispatch } from '@reduxjs/toolkit';
import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, useFormValidation } from 'reactjs-forms';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import FormInput from 'src/components/basic/form/form-input';
import FormTitle from 'src/components/basic/form/form-title';
import Loader from 'src/components/basic/loader';
import PageContainer from 'src/pages/components/page-container';
import {
  isEqulPasswords,
  isValidPassword,
  setUserSuccess,
} from 'src/services/util';
import { resetPassword } from 'src/store/user';

export default function ResetPasswordConfirm() {
  const queryParam = new URLSearchParams(window.location.search);
  const reset_key = queryParam.get('key') ?? '';

  const { t } = useTranslation();
  const navigate = useNavigate();
  const validation = useFormValidation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { is_loading, is_success } = useSelector((state: any) => state.user);
  const { error_msg } = useSelector((state: any) => state.error);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (!is_loading && is_success) {
      toast.success(t('reset-password-confirm.success'));
      setUserSuccess(false);
      navigate('/login');
    }
  }, [is_loading, is_success]);

  useEffect(() => {
    error_msg.map((msg: string) => {
      toast.error(msg);
    });
  }, [error_msg]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'password':
        setPassword(value);
        break;
      case 'confirm_password':
        setConfirmPassword(value);
        break;
    }
  };

  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    const { isValid } = validation();
    if (isValid && password && confirmPassword) {
      dispatch(resetPassword({ password, reset_key }));
      setPassword('');
      setConfirmPassword('');
    }
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <Form onSubmit={submitHandler} autoComplete="off">
        <div className="hero min-h-screen bg-base-200">
          <div className="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
            <div className="card-body">
              <FormTitle title={t('reset-password-confirm.title')} />
              <FormInput
                label={t('reset-password-confirm.new_password')}
                type={'password'}
                placeholder={t('reset-password-confirm.enter_new_password')}
                identity="password"
                id="password"
                value={password}
                onChange={changeHandler}
                customValidation={{
                  isValidPassword: isValidPassword(password),
                }}
              />

              <FormInput
                label={t('reset-password-confirm.confirm_password')}
                type={'password'}
                placeholder={t('reset-password-confirm.confirm_password')}
                identity="confirm_password"
                id="confirm_password"
                value={confirmPassword}
                onChange={changeHandler}
                customValidation={{
                  isEqulPasswords: isEqulPasswords(password),
                }}
              />

              <div className="form-control mt-6">
                <button className="btn btn-accent text-white">
                  {t('reset-password-confirm.title')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </PageContainer>
  );
}
