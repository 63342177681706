import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import extraReducer from './extra';
import surveyReducer from './survey';
import userReducer from './user';
import errorReducer from './error';

const reducers = combineReducers({
  extra: extraReducer,
  survey: surveyReducer,
  user: userReducer,
  error: errorReducer,
});

export const store = configureStore({
  reducer: reducers,
});
