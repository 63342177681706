import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { survey_type as survey_types } from 'src/services/config';

const QuestionHeader = () => {
  const { t } = useTranslation();

  const { survey_type } = useSelector((state: any) => state.survey);

  return (
    <div className="flex flex-row my-4 py-2 text-gray-900 font-bold uppercase">
      <div className="basis-1/2">
        <span className="text-2xl">{t('public_survey.questions')}</span>
      </div>
      <div className="basis-1/3 grid grid-cols-3 gap-4 ml-6">
        <span>{t('public_survey.answer_yes')}</span>
        <span>{t('public_survey.answer_no')}</span>
        <span>{t('public_survey.answer_na')}</span>
      </div>
      {survey_type == survey_types.survey_type_public && (
        <div className="basis-1/6 w-16 text-center">
          <div className="px-4">{t('public_survey.risk_level')}</div>
        </div>
      )}
    </div>
  );
};

export default QuestionHeader;
