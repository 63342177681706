import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { survey_type as survey_types } from 'src/services/config';
const SectionTitle = (props: { title: string }) => {
  const navigate = useNavigate();
  const { survey_type } = useSelector((state: any) => state.survey);

  const onClick = useCallback(() => {
    if (survey_type == survey_types.survey_type_public) {
      goHome();
    } else if (survey_type == survey_types.survey_type_registered) {
      goSurveyWelcome();
    }
  }, [survey_type]);

  const goHome = () => {
    navigate('/home');
  };

  const goSurveyWelcome = () => {
    navigate('/survey-welcome');
  };

  return (
    <span
      className="px-2 mb-2 text-white text-xl font-bold font-calibri line-clamp-2 text-center cursor-pointer"
      onClick={onClick}>
      {props.title}
    </span>
  );
};

export default SectionTitle;
