import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/basic/loader';

import { clearToken } from 'src/services/storage';
import { setUserLoggedIn, setUserLoggedOut } from 'src/services/util';
import { logout } from 'src/store/user';

export interface MenuItemProps {
  id: string;
  title: string;
  active: boolean;
  menuLink: string;
}

const MenuItem = (props: MenuItemProps) => {
  const { id, title, active, menuLink } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { is_loading, is_logged_out } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (is_logged_out) {
      onLogout();
    }
  }, [is_logged_out]);

  const onItemClicked = () => {
    if (id == 'logout') {
      dispatch(logout());
    } else {
      navigate(menuLink);
    }
  };

  const onLogout = () => {
    clearToken();
    setUserLoggedOut(false);
    setUserLoggedIn(false);
    setTimeout(() => {
      navigate('/home');
    }, 50);
  };

  return (
    <>
      {is_loading && <Loader />}
      <li onClick={onItemClicked}>
        <a
          className={`text-white ${
            active ? 'bg-secondary' : ''
          } hover:bg-accent-2 hover:text-white`}>
          <span className="line-clamp-4 font-calibri">{title}</span>
        </a>
      </li>
    </>
  );
};
export default MenuItem;
