import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './layout/navbar';
import Footer from './layout/footer';
import AuthLayout from './layout/auth-layout';
import AuthFormLayout from './layout/auth-form';

import Home from './pages/home';
import SignUp from './pages/auth/signup';
import Login from './pages/auth/login';
import VerifyEmailSent from './pages/auth/verify/verify-email-sent';
import Verify from './pages/auth/verify/verify';
import PublicSurvey from './pages/survey/public-survey';
import RegisteredSurvey from './pages/survey/registered-survey';
import ResetPassword from './pages/auth/password-reset/reset-password';
import ResetPasswordConfirm from './pages/auth/password-reset/reset-password-confirm';
import { isLoggedIn } from './services/storage';
import SurveyWelcome from './pages/survey/survey-welcome';

import Account from './pages/account';
import Profile from './pages/account/profile';
import ChangePassword from './pages/account/change-password';

function App() {
  const { i18n } = useTranslation();
  const queryParam = new URLSearchParams(window.location.search);
  const lang = queryParam.get('lang');

  useEffect(() => {
    isLoggedIn();
  }, []);

  useEffect(() => {
    if (!lang) {
      queryParam.set('lang', i18n.language);
      window.history.replaceState({}, '', `?${queryParam}`);
    }
  }, [lang]);
  const { show_navbar } = useSelector((state: any) => state.extra);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <>
          {show_navbar && <NavBar />}
          <Routes>
            <Route path="" element={<AuthLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="public-survey" element={<PublicSurvey />} />
              <Route path="" element={<AuthFormLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="forgot-password" element={<ResetPassword />} />
                <Route
                  path="passwordResetConfirm"
                  element={<ResetPasswordConfirm />}
                />
              </Route>
              <Route path="verify-email-sent" element={<VerifyEmailSent />} />
              <Route path="validateNewUser" element={<Verify />} />
              <Route path="registered-survey" element={<RegisteredSurvey />} />
              <Route path="survey-welcome" element={<SurveyWelcome />} />
              <Route path="/account" element={<Account />} />

              <Route path="" element={<AuthFormLayout />}>
                <Route path="/profile" element={<Profile />} />
                <Route path="/change-password" element={<ChangePassword />} />
              </Route>
            </Route>
          </Routes>
          {show_navbar && <Footer />}
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
