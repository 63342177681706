import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'src/i18n';

import { ISurveyQuestion } from 'src/model/survey/question';
import { setAnswer } from 'src/services/util';
import { updateAnswer } from 'src/store/survey';

import { survey_type as survey_types } from 'src/services/config';

const QuestionItem = (props: { question_item: ISurveyQuestion }) => {
  const { question_item } = props;
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { selected_section_id, survey_type, response, highlight_unanswered } = useSelector(
    (state: any) => state.survey,
  );

  const getRiskText = () => {
    const answer = question_item.selected_answer;
    if (!answer) {
      return '';
    }

    let lang: 'en' | 'id' = 'en';
    if (i18n.language == 'id') {
      lang = 'id';
    }

    return question_item.configuration.lotRiskResponses[answer].text[lang];
  };

  const getRiskBG = () => {
    const answer = question_item.selected_answer;
    if (!answer) {
      return '#ffffff';
    }

    return question_item.configuration.lotRiskResponses[answer].color;
  };

  const getRiskColor = () => {
    const answer = question_item.selected_answer;
    if (!answer || answer == 'na') {
      return 'text-transparent';
    }

    return '';
  };

  const getRiskHidden = () => {
    const answer = question_item.selected_answer;
    return answer ? '' : 'hidden';
  };

  const onAnswer = useCallback(
    (answer: string) => {
      setAnswer(selected_section_id, question_item.id, answer);
      dispatch(
        updateAnswer({
          response_id: response?.id ?? '',
          question_id: question_item.id,
          answer_text: answer,
        }),
      );
    },
    [setAnswer, response],
  );

  const highlightUnanswered = () => {
    const answer = question_item.selected_answer;
    if(!answer && highlight_unanswered){
      return 'text-red-500';
    } 
    return '';
  };

  return (
    <div className="flex flex-row my-4 px-4 py-2 items-center text-gray-900">
      <div className="basis-1/2">
        <span className={`text-left max-w-2xl text-xl tracking-tight leading-snug ${highlightUnanswered()}`}>
          {question_item.question_number}. {question_item.text}
        </span>
      </div>
      <div className="basis-1/3 grid grid-cols-3 gap-4 ml-6">
        <input
          type="radio"
          name={`radio-${selected_section_id}-${question_item.id}`}
          className="radio radio-primary checked:bg-red-500"
          checked={question_item.selected_answer == 'yes'}
          onChange={() => onAnswer('yes')}
        />
        <input
          type="radio"
          name={`radio-${selected_section_id}-${question_item.id}`}
          className="radio radio-primary checked:bg-red-500"
          checked={question_item.selected_answer == 'no'}
          onChange={() => onAnswer('no')}
        />
        <input
          type="radio"
          name={`radio-${selected_section_id}-${question_item.id}`}
          className="radio radio-primary checked:bg-red-500"
          checked={question_item.selected_answer == 'na'}
          onChange={() => onAnswer('na')}
        />
      </div>
      {survey_type == survey_types.survey_type_public && (
        <div className="basis-1/6 w-16 text-center">
          <div
            className={`px-4 py-2 min-h-[40px] ${getRiskHidden()} ${getRiskColor()} capitalize`}
            style={{ background: getRiskBG() }}>
            {getRiskText()}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
