import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PageContainer from '../components/page-container';
import Loader from 'src/components/basic/loader';
import { useDispatch, useSelector } from 'react-redux';
import { Form, useFormValidation } from 'reactjs-forms';
import FormInput from 'src/components/basic/form/form-input';
import { useTranslation } from 'react-i18next';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import {
  _isEqualPasswords,
  _isValidPassword,
  isEqulPasswords,
  isValidPassword,
  setIsPasswordUpdate,
} from 'src/services/util';
import FormTitle from 'src/components/basic/form/form-title';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getUserData, updatePassword } from 'src/store/user';
import { toast } from 'react-toastify';

interface PasswordFormProps {
  current_password: string;
  password: string;
  confirm_password: string;
}

const ChangePassword = () => {
  const { t } = useTranslation();
  const validation = useFormValidation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { is_loading, is_password_updated, userData, is_logged_in } =
    useSelector((state: any) => state.user);
  const { error_msg } = useSelector((state: any) => state.error);

  const [data, setData] = useState<PasswordFormProps>({
    current_password: '',
    password: '',
    confirm_password: '',
  });

  useEffect(() => {
    setIsPasswordUpdate(false);

    return () => {
      setIsPasswordUpdate(false);
    };
  }, []);

  useEffect(() => {
    if (is_logged_in) {
      dispatch(getUserData());
    } else {
      navigate('/login');
    }
  }, [is_logged_in]);

  useEffect(() => {
    if (is_password_updated) {
      toast.success(t('password_updated'));
      navigate('/survey-welcome');
    }
  }, [is_password_updated]);

  useEffect(() => {
    error_msg.map((msg: string) => {
      toast.error(msg);
    });
  }, [error_msg]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'current_password':
        setData({ ...data, current_password: value });
        break;
      case 'password':
        setData({ ...data, password: value });
        break;
      case 'confirm_password':
        setData({ ...data, confirm_password: value });
        break;
    }
  };

  const isValid = useMemo(() => {
    return (
      _isValidPassword(data.password) &&
      _isValidPassword(data.current_password) &&
      _isEqualPasswords(data.password, data.confirm_password)
    );
  }, [data]);

  const email = useMemo(() => {
    return userData?.email ?? '';
  }, [userData]);

  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    const { isValid } = validation();
    if (
      isValid &&
      email &&
      data.current_password &&
      data.password &&
      data.confirm_password
    ) {
      const payload = {
        email: email,
        current_password: data.current_password,
        new_password: data.password,
      };

      dispatch(updatePassword(payload));

      setData({
        current_password: '',
        password: '',
        confirm_password: '',
      });
    }
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <div className="sm:px-2 lg:px-16" data-theme="light">
        <div className="my-4 py-2 text-gray-900">
          <Form onSubmit={submitHandler} autoComplete="off">
            <div className="hero my-16">
              <div className="card flex-shrink-0 w-full max-w-2xl shadow-2xl bg-base-100">
                <div className="card-body">
                  <FormTitle title={t('change_password.title')} />
                  <FormInput
                    label={t('change_password.current_password')}
                    type={'password'}
                    placeholder={t('change_password.enter_current_password')}
                    identity="current_password"
                    id="current_password"
                    value={data.current_password}
                    onChange={changeHandler}
                    customValidation={{
                      isValidPassword: isValidPassword(data.current_password),
                    }}
                  />
                  <FormInput
                    label={t('change_password.password')}
                    type={'password'}
                    placeholder={t('change_password.enter_new_password')}
                    identity="password"
                    id="password"
                    value={data.password}
                    onChange={changeHandler}
                    customValidation={{
                      isValidPassword: isValidPassword(data.password),
                    }}
                  />

                  <FormInput
                    label={t('change_password.confirm_password')}
                    type={'password'}
                    placeholder={t('signup.confirm_password')}
                    identity="confirm_password"
                    id="confirm_password"
                    value={data.confirm_password}
                    onChange={changeHandler}
                    customValidation={{
                      isEqulPasswords: isEqulPasswords(data.password),
                    }}
                  />

                  <div className="form-control mt-6">
                    <button
                      className="btn btn-primary text-white"
                      disabled={!isValid}>
                      {t('change_password.title')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </PageContainer>
  );
};

export default ChangePassword;
