import { IToken } from 'src/model/user/token';
import { setUserLoggedIn } from './util';

const SESSION_TOKEN = 'SESSION_TOKEN';
const SECURITY_TOKEN = 'SECURITY_TOKEN';

export function saveToken(token: IToken) {
  localStorage.setItem(SESSION_TOKEN, token.session_jwt ?? '');
  localStorage.setItem(SECURITY_TOKEN, token.security_jwt ?? '');
}

export function readToken(): IToken {
  const session_jwt = localStorage.getItem(SESSION_TOKEN) ?? '';
  const security_jwt = localStorage.getItem(SECURITY_TOKEN) ?? '';
  return { session_jwt, security_jwt };
}

export function clearToken() {
  localStorage.removeItem(SESSION_TOKEN);
  localStorage.removeItem(SECURITY_TOKEN);
}

export function isLoggedIn() {
  const token: IToken = readToken();

  if (!token) {
    return false;
  }

  if (!token.security_jwt) {
    return false;
  }

  let is_valid = false;
  const decodedJwt = parseJwt(token.security_jwt);

  if (decodedJwt && decodedJwt.exp && decodedJwt.exp * 1000 >= Date.now()) {
    is_valid = true;
  }

  if (!is_valid) {
    clearToken();
  }

  setUserLoggedIn(is_valid);

  return is_valid;
}

export const getUserId = () => {
  const token: IToken = readToken();
  if (!token) {
    return null;
  }

  if (!token.security_jwt) {
    return null;
  }

  let is_valid = false;
  const decodedJwt = parseJwt(token.security_jwt);

  if (decodedJwt && decodedJwt.exp && decodedJwt.exp * 1000 >= Date.now()) {
    is_valid = true;
  }

  if (!is_valid) {
    return null;
  }

  return decodedJwt.sub;
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export function createStorage(name: string, value: string) {
  localStorage.setItem(name, value ?? '');
}

export function getStorage(name: string) {
  const storageVal = localStorage.getItem(name) ?? '';
  return storageVal;
}
