import React from 'react';
import SectionBox from 'src/components/section/section-box';
import MenuBox from './components/menu-box';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'src/services/storage';

export default function SideMenu() {
  const { survey_type } = useSelector((state: any) => state.survey);

  return (
    <div>
      {isLoggedIn() && <MenuBox />}
      {survey_type != undefined && (
        <div className="my-4">
          <SectionBox />
        </div>
      )}
    </div>
  );
}
