import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error_msg: [],
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,

  reducers: {
    setErrorMsg(state, action) {
      state.error_msg = action.payload;
    },
  },
});

export const { setErrorMsg } = errorSlice.actions;

export default errorSlice.reducer;
