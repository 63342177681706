import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface AnchorProps {
  className?: string;
  href?: string;
  target?: string;
  children?: ReactNode | ReactNode[] | string;
  onClick?: () => void;
}

const Link = ({ className, href, target, children }: AnchorProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(href ?? '/');
  };

  return (
    <a
      className={`underline ${className}`}
      href={''}
      target={target}
      onClick={onClick}>
      {children}
    </a>
  );
};

export default Link;
