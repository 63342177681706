import React from 'react';
import LangIcon from './language-icon';
import { langs } from '../../../services/lang-config';

const LanguageSwitcher = () => {
  return (
    <div className="text-right">
      <div className="text-right flex items-center gap-2 justify-end">
        {Object.keys(langs).map((lng) => (
          <LangIcon key={lng} code={lng} />
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
