import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import { Form, useFormValidation } from 'reactjs-forms';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/page-container';
import FormInput from 'src/components/basic/form/form-input';
import Link from 'src/components/basic/link';
import FormTitle from 'src/components/basic/form/form-title';
import {
  isValidEmail,
  isValidPassword,
  setUserSuccess,
} from 'src/services/util';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { login } from 'src/store/user';
import { toast } from 'react-toastify';
import Loader from 'src/components/basic/loader';
import ConfirmDialog from 'src/components/dialog/confirm-dialog';
import { useNavigate } from 'react-router-dom';
import { cancelResponse, getResponses } from 'src/store/survey';
import { registered_survey_id } from 'src/services/config';
import { getUserId } from 'src/services/storage';
import FormRadio from 'src/components/basic/form/form-radio';
import * as Storage from 'src/services/storage';

interface LoginFormProps {
  email: string;
  password: string;
}

export default function Login() {
  const confirm_modal_id = 'login_confirm_modal';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const validation = useFormValidation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { is_loading, is_success, is_logged_in } = useSelector(
    (state: any) => state.user,
  );
  const { error_msg } = useSelector((state: any) => state.error);
  const { response_list, response, is_response_get, is_response_canceled } =
    useSelector((state: any) => state.survey);
  const is_survey_loading = useSelector(
    (state: any) => state.survey.is_loading,
  );

  const [payload, setPayload] = useState<LoginFormProps>({
    email: '',
    password: '',
  });

  const [dlgOption, setDlgOption] = useState(0);
  const [dlgOpen, setDlgOpen] = useState(false);

  useEffect(() => {
    if (!is_loading && is_success && is_logged_in) {
      toast.success(t('login.success'));

      dispatch(
        getResponses({
          survey_id: registered_survey_id,
          user_id: getUserId(),
          status: 'DRAFT',
        }),
      );
    }
    if (!is_success && is_logged_in) {
      navigate('/home');
    }
  }, [is_loading, is_success, is_logged_in]);

  useEffect(() => {
    error_msg.map((msg: string) => {
      if (t(`login.${msg}`) != `login.${msg}`) {
        toast.error(t(`login.${msg}`));
      } else {
        toast.error(msg);
      }
    });
  }, [error_msg]);

  useEffect(() => {
    if (is_response_get && is_logged_in) {
      if (response_list.length > 0) {
        // Show Dialog
        setDlgOpen(true);
      } else if (response) {
        goRegisterSurvey();
      } else {
        if (!Storage.getStorage('firstLogin')) {
          Storage.createStorage('firstLogin', 'yes');
          navigate('/account');
        } else {
          goSurveyWelcome();
        }
      }
    }
  }, [response_list, response, is_response_get]);

  // Go to survey welcome After Cancel
  useEffect(() => {
    if (is_response_canceled) {
      goSurveyWelcome();
    }
  }, [is_response_canceled]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'email':
        setPayload({ ...payload, email: value });
        break;
      case 'password':
        setPayload({ ...payload, password: value });
        break;
    }
  };

  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    const { isValid } = validation();
    if (isValid && payload.email && payload.password) {
      dispatch(login(payload));
    }
  };

  const onClose = () => {
    if (dlgOption != 1 && dlgOption != 2) {
      toast.error(t('welcome_back_dlg.please_select_option'));
      return;
    }

    setDlgOpen(false);
    setDlgOption(0);

    if (dlgOption == 1) {
      goRegisterSurvey();
    } else if (dlgOption == 2) {
      dispatch(cancelResponse({ response_id: response.id }));
    }
  };

  const goRegisterSurvey = () => {
    setUserSuccess(false);
    navigate('/registered-survey');
  };

  const goSurveyWelcome = () => {
    setUserSuccess(false);
    navigate('/survey-welcome');
  };

  return (
    <PageContainer>
      {(is_loading || is_survey_loading) && <Loader />}
      <Form onSubmit={submitHandler} autoComplete="off">
        <div className="hero min-h-screen bg-base-200">
          <div className="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
            <div className="card-body">
              <FormTitle title={t('login.title')} />
              <FormInput
                label={t('login.email')}
                type={'email'}
                placeholder={t('login.enter_email')}
                identity="email"
                id="email"
                value={payload.email}
                onChange={changeHandler}
                customValidation={{
                  isValidEmail: isValidEmail(t('login.email'), payload.email),
                }}
              />
              <FormInput
                label={t('login.password')}
                type={'password'}
                placeholder={t('login.enter_password')}
                identity="password"
                id="password"
                value={payload.password}
                onChange={changeHandler}
                customValidation={{
                  isValidPassword: isValidPassword(payload.password),
                }}
              />

              <span className="text-sm mt-4">
                {t('login.forgot_password')}
                <Link
                  href="/forgot-password"
                  className="text-primary link link-hover px-1">
                  {t('login.reset_password')}
                </Link>
              </span>

              <div className="form-control mt-6">
                <button className="btn btn-accent">{t('login.title')}</button>
              </div>

              <span className="text-sm mt-2">
                {t('login.new_to_here')}
                <Link
                  href="/signup"
                  className="text-primary link link-hover px-1">
                  {t('signup.title')}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Form>
      <ConfirmDialog
        modal_id={confirm_modal_id}
        is_open={dlgOpen}
        title={t('welcome_back_dlg.title')}
        close_text={t('submit')}
        onClose={onClose}>
        <span>{t('welcome_back_dlg.text')}</span>
        <div className="mt-8">
          <FormRadio
            name={'welcome_back_dlg_option'}
            text={t('welcome_back_dlg.option_1')}
            is_checked={dlgOption == 1}
            onChange={() => setDlgOption(1)}
          />
          <FormRadio
            name={'welcome_back_dlg_option'}
            text={t('welcome_back_dlg.option_2')}
            is_checked={dlgOption == 2}
            onChange={() => setDlgOption(2)}
          />
        </div>
      </ConfirmDialog>
    </PageContainer>
  );
}
