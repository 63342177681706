import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import { Form, useFormValidation } from 'reactjs-forms';
import { Trans, useTranslation } from 'react-i18next';
import PageContainer from '../components/page-container';
import FormInput from 'src/components/basic/form/form-input';
import FormLabel from 'src/components/basic/form/form-label';
import Link from 'src/components/basic/link';
import FormTitle from 'src/components/basic/form/form-title';
import {
  isEqulPasswords,
  isValidEmail,
  isValidName,
  isValidPassword,
  setUserSuccess,
} from 'src/services/util';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/basic/loader';
import { signup } from 'src/store/user';
import ConfirmDialog from 'src/components/dialog/confirm-dialog';
import Terms from 'src/components/terms/terms';

interface SignUpFormProps {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
}

export default function SignUp() {
  const terms_modal_id = 'terms_modal';

  const { t } = useTranslation();
  const validation = useFormValidation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { is_loading, is_success } = useSelector((state: any) => state.user);
  const { error_msg } = useSelector((state: any) => state.error);

  const [payload, setPayload] = useState<SignUpFormProps>({
    email: '',
    first_name: '',
    last_name: '',
    password: '',
    confirm_password: '',
  });

  const [dlgOpen, setDlgOpen] = useState(false);

  useEffect(() => {
    if (!is_loading && is_success) {
      toast.success(t('signup.success'));
      setUserSuccess(false);
      navigate('/verify-email-sent');
    }
  }, [is_loading, is_success]);

  useEffect(() => {
    error_msg.map((msg: string) => {
      toast.error(msg);
    });
  }, [error_msg]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'email':
        setPayload({ ...payload, email: value });
        break;
      case 'first_name':
        setPayload({ ...payload, first_name: value });
        break;
      case 'last_name':
        setPayload({ ...payload, last_name: value });
        break;
      case 'password':
        setPayload({ ...payload, password: value });
        break;
      case 'confirm_password':
        setPayload({ ...payload, confirm_password: value });
        break;
    }
  };

  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {

    e.preventDefault();
    // touch inputs to fire validation
    for (const element of document.getElementsByTagName('input')){
      element.focus();
      element.blur();
    }
    
    const { isValid } = validation();
    if (
      isValid &&
      payload.email &&
      (payload.first_name.trim() || payload.last_name.trim()) &&
      payload.password &&
      payload.confirm_password
    ) {
      dispatch(
        signup({
          given_name: payload.first_name,
          surname: payload.last_name,
          email: payload.email,
          password: payload.password,
        }),
      );

      setPayload({
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: '',
      });
    }
  };

  const onTerms = () => {
    setDlgOpen(true);
  };

  const onClose = () => {
    setDlgOpen(false);
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <Form onSubmit={submitHandler} autoComplete="off">
        <div className="hero min-h-screen bg-base-200">
          <div className="card flex-shrink-0 w-full max-w-lg shadow-2xl bg-base-100">
            <div className="card-body">
              <FormTitle title={t('signup.title')} />
              <FormInput
                label={t('signup.email') + ' *'}
                type={'email'}
                placeholder={t('signup.email')}
                identity="email"
                id="email"
                value={payload.email}
                onChange={changeHandler}
                customValidation={{
                  isValidEmail: isValidEmail(t('signup.email'), payload.email),
                }}
              />
              <FormLabel
                label={t('signup.please_note')}
                text={t('signup.note')}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInput
                  label={t('signup.first_name')}
                  type={'text'}
                  placeholder={t('signup.first_name')}
                  identity="first_name"
                  id="first_name"
                  value={payload.first_name}
                  onChange={changeHandler}
                />
                <FormInput
                  label={t('signup.last_name')}
                  type={'text'}
                  placeholder={t('signup.last_name')}
                  identity="last_name"
                  id="last_name"
                  value={payload.last_name}
                  onChange={changeHandler}
                  customValidation={{
                    isValidName: isValidName(
                      payload.first_name.trim() + payload.last_name.trim(),
                    ),
                  }}
                />
              </div>
              <FormInput
                label={t('signup.password') + ' *'}
                type={'password'}
                placeholder={t('signup.enter_password')}
                identity="password"
                id="password"
                value={payload.password}
                onChange={changeHandler}
                customValidation={{
                  isValidPassword: isValidPassword(payload.password),
                }}
              />

              <FormInput
                label={t('signup.confirm_password') + ' *'}
                type={'password'}
                placeholder={t('signup.confirm_password')}
                identity="confirm_password"
                id="confirm_password"
                value={payload.confirm_password}
                onChange={changeHandler}
                customValidation={{
                  isEqulPasswords: isEqulPasswords(payload.password),
                }}
              />

              <span className="text-sm mt-4">
                <Trans i18nKey="signup.description">
                  {''}
                  <a
                    onClick={onTerms}
                    className="text-primary link link-hover px-1">
                    terms and conditions
                  </a>
                </Trans>
              </span>

              <div className="form-control mt-6">
                <button className="btn btn-primary">{t('signup.title')}</button>
              </div>

              <span className="text-sm mt-2">
                {t('signup.already_have_account')}
                <Link
                  href="/login"
                  className="text-accent link link-hover px-1">
                  {t('login.title')}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Form>
      <ConfirmDialog
        modal_id={terms_modal_id}
        is_open={dlgOpen}
        title={t('terms_dlg.title')}
        close_text={t('terms_dlg.close')}
        onClose={onClose}>
        <Terms />
      </ConfirmDialog>
    </PageContainer>
  );
}
