import React, { useEffect } from 'react';
import PageContainer from 'src/pages/components/page-container';
import Image from 'src/components/basic/image';
import MessageSection from '../../components/message-section';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setShowNavBar } from 'src/services/util';

export default function VerifyEmailSent() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowNavBar(false);
  }, []);

  const login = () => {
    setShowNavBar(true);
    navigate('/login');
  };

  return (
    <PageContainer>
      <div className="hero min-h-screen bg-base-200">
        <div className="card w-full max-w-lg shadow-2xl bg-base-100">
          <div className="card-body">
            <Image
              className="w-6/12 ml-auto mr-auto"
              src="assets/images/verify-email.png"
            />
            <h2 className="card-title justify-center text-3xl">
              {t('verify.title')}
            </h2>

            <MessageSection
              alert_type="warning"
              message={t('verify.check_mail_box')}
              onClick={login}
              button_text={t('login.title')}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
