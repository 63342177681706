import React from 'react';

interface FormRadioProps {
  name: string;
  text: string;
  is_checked: boolean;
  onChange: () => void;
}

const FormRadio = ({ name, text, is_checked, onChange }: FormRadioProps) => {
  return (
    <div className="form-control">
      <label className="cursor-pointer label justify-start">
        <input
          type="radio"
          name={name}
          className="radio radio-success"
          checked={is_checked}
          onChange={onChange}
        />
        <span className="label-text ml-2">{text}</span>
      </label>
    </div>
  );
};

export default FormRadio;
