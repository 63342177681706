import React, { FC, FocusEventHandler } from 'react';
import { InputProps } from 'reactjs-forms/types';
interface RadioInputProps extends InputProps {
  label?: string;
  name?: string;
  id?: string;
  value?: string;
}
const Radio: FC<RadioInputProps> = ({ onFocus, ...props }) => {
  const focusHandler: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onFocus) onFocus(e);
  };
  return (
    <div className="flex items-center mr-4">
      <label className="cursor-pointer label justify-start">
        <span className="label-text ml-2">{props.label}</span>
      </label>
      <input
        type="radio"
        name={props.name}
        id={props.id}
        value={props.value}
        className="radio radio-success"
        onFocus={focusHandler}
        {...props}
      />
    </div>
  );
};
export default Radio;
