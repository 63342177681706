import React, { FC, FocusEventHandler, useState } from 'react';
import ErrorList from './error-list';
import { InputProps, ValidationResult } from 'reactjs-forms/types';
import { Input, useFormValidation } from 'reactjs-forms';
import Image from '../image';
interface FormInputProps extends InputProps {
  label: string;
}

const FormInput: FC<FormInputProps> = ({ onBlur, onFocus, ...props }) => {
  const validation = useFormValidation();

  const [errorList, setErrorList] = useState<ValidationResult>([]);
  const [visible, setVisible] = useState(false);

  const blurHandler: FocusEventHandler<HTMLInputElement> = (e) => {
    setErrorList(validation(props.identity!).result[props.identity!]);
    if (onBlur) onBlur(e);
  };

  const focusHandler: FocusEventHandler<HTMLInputElement> = (e) => {
    setErrorList([]);
    if (onFocus) onFocus(e);
  };

  const onVisible = () => {
    setVisible(!visible);
  };

  const getInputType = () => {
    if (props.type == 'password') {
      return visible ? 'text' : 'password';
    } else {
      return props.type;
    }
  };

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text font-bold">{props.label}</span>
      </label>
      <div className="relative">
        <Input
          className={`w-full input input-bordered ${
            errorList?.length > 0 ? 'error-element' : ''
          }`}
          {...props}
          type={getInputType()}
          onBlur={blurHandler}
          onFocus={focusHandler}
        />
        {props.type == 'password' && (
          <Image
            className="w-6 absolute right-3 bottom-3"
            src={`assets/icons/${visible ? 'invisible' : 'visible'}.png`}
            onClick={() => onVisible()}
          />
        )}
      </div>
      <ErrorList errors={errorList} />
    </div>
  );
};

export default FormInput;
