import React, {
  useState,
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/page-container';
import QuestionList from './components/questions-list';
import SectionHeading from 'src/components/basic/title/section-heading';
import { Form } from 'reactjs-forms';
import FormInput from 'src/components/basic/form/form-input';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserAccountData,
  getUserData,
  updateAccountData,
  updateUserData,
} from 'src/store/user';
import Loader from 'src/components/basic/loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isValidName } from 'src/services/util';
interface ProfileFormProps {
  given_name: string;
  surname: string;
}
const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const {
    is_loading,
    userData,
    submission_error,
    is_submitted,
    accountData,
    is_logged_in,
  } = useSelector((state: any) => state.user);
  const [showError, setShowError] = useState(false);
  const [questionareFields, setQuestionareFields] = useState({
    feedlot_name: '',
    feedlot_location: '',
    number_of_cattle: '',
    accept_local_cattle: '',
    accept_australian_cattle: '',
  });
  const [userEmail, setuserEmail] = useState('');
  const [profileFields, setProfileFields] = useState<ProfileFormProps>({
    given_name: '',
    surname: '',
  });

  useEffect(() => {
    if (is_logged_in) {
      dispatch(getUserAccountData());
      dispatch(getUserData());
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (showError) {
      window.scrollTo(0, 0);
      toast.error(t('required_message'));
    }
    if (!is_loading) {
      if (userData && !submission_error && !profileFields.given_name) {
        setProfileFields({
          given_name: userData.given_name,
          surname: userData.surname,
        });
        setuserEmail(userData.email);
      }

      if (
        Object.keys(accountData).length > 0 &&
        !submission_error &&
        !questionareFields.feedlot_name
      ) {
        setQuestionareFields({
          feedlot_name: accountData.feedlotName,
          feedlot_location: accountData.feedlotLocation,
          number_of_cattle: accountData.numberOfCattle,
          accept_local_cattle: accountData.acceptLocalCattle,
          accept_australian_cattle: accountData.acceptAustralianCattle,
        });
      } else if (Object.keys(accountData).length == 0) {
        navigate('/account');
      }

      if (is_submitted && !submission_error && !showError) {
        window.scrollTo(0, 0);
        toast.success(t('updated_success'));
        navigate('/survey-welcome');
      }
    }
  }, [
    is_loading,
    accountData,
    userData,
    submission_error,
    showError,
    is_submitted,
  ]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setShowError(false);
    const { id, value } = e.target;
    switch (id) {
      case 'given_name':
        setProfileFields({ ...profileFields, given_name: value });
        break;
      case 'surname':
        setProfileFields({ ...profileFields, surname: value });
        break;
      case 'feedlot_name':
        setQuestionareFields({ ...questionareFields, feedlot_name: value });
        break;
      case 'feedlot_location':
        setQuestionareFields({ ...questionareFields, feedlot_location: value });
        break;
      case 'number_of_cattle':
        setQuestionareFields({ ...questionareFields, number_of_cattle: value });
        break;
      case 'accept_local_cattle':
        setQuestionareFields({
          ...questionareFields,
          accept_local_cattle: value,
        });
        break;
      case 'accept_australian_cattle':
        setQuestionareFields({
          ...questionareFields,
          accept_australian_cattle: value,
        });
        break;
    }
  };
  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    if (
      (profileFields.given_name.trim() ||
      profileFields.surname.trim()) &&
      questionareFields.feedlot_name &&
      questionareFields.feedlot_location &&
      questionareFields.number_of_cattle &&
      !showError
    ) {
      dispatch(updateUserData(profileFields));
      dispatch(updateAccountData(questionareFields));
    } else {
      setShowError(true);
    }
  };
  return (
    <PageContainer>
      {is_loading && <Loader />}
      <div className="sm:px-2 lg:px-16">
        <div className="my-4 py-2 text-gray-900">
          <div className="basis-auto">
            <Form
              onSubmit={submitHandler}
              autoComplete="off"
              data-theme="light">
              <div className="flex flex-col space-y-20">
                <div>
                  <SectionHeading title={t('profile.title')} />
                  {showError && (
                    <p className="text-sm text-red-500 ">
                      {t('required_message')}
                    </p>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormInput
                      label={t('signup.first_name')}
                      type={'text'}
                      placeholder={t('signup.first_name')}
                      identity="given_name"
                      id="given_name"
                      value={profileFields.given_name}
                      onChange={changeHandler}
                    />
                    <FormInput
                      label={t('signup.last_name')}
                      type={'text'}
                      placeholder={t('signup.last_name')}
                      identity="surname"
                      id="surname"
                      value={profileFields.surname}
                      onChange={changeHandler}
                      customValidation={{
                        isValidName: isValidName(
                          profileFields.given_name.trim() + profileFields.surname.trim(),
                        ),
                      }}
                    />
                  </div>
                  <FormInput
                    label={t('signup.email')}
                    type={'email'}
                    placeholder={t('signup.email')}
                    identity="email"
                    id="email"
                    readOnly
                    disabled
                    value={userEmail}
                  />
                </div>
                <div>
                  <SectionHeading title={t('profile.assessment_title')} />
                  <QuestionList
                    payload={questionareFields}
                    onChange={changeHandler}
                  />
                  <div className="form-control mt-6 float-right">
                    <button className="btn btn-primary w-52 text-white">
                      {t('profile.submit')}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};
export default Profile;
