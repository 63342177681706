import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/page-container';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { getUserVerified } from 'src/store/user';
import { setShowNavBar } from 'src/services/util';
import Loader from 'src/components/basic/loader';
import Image from 'src/components/basic/image';
import MessageSection from '../../components/message-section';

export default function Verify() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const queryParam = new URLSearchParams(window.location.search);
  const usrKey = queryParam.get('key');
  const payload = {
    validation_token: usrKey,
  };

  const { is_verified, is_loading, is_success } = useSelector(
    (state: any) => state.user,
  );

  useEffect(() => {
    setShowNavBar(false);
    dispatch(getUserVerified(payload));
  }, []);

  const login = () => {
    setShowNavBar(true);
    navigate('/login');
  };

  const verifyUser = () => {
    dispatch(getUserVerified(payload));
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <div className="hero min-h-screen bg-base-200">
        <div className="card w-full max-w-lg shadow-2xl bg-base-100">
          <div className="card-body">
            <Image
              className="w-6/12 ml-auto mr-auto"
              src="assets/images/verify-email.png"
              alt="verified"
            />
            <h2 className="card-title justify-center text-3xl">
              {t('verify.title')}
            </h2>

            {is_verified && is_success && (
              <MessageSection
                alert_type="tick"
                message={t('verify.success_message')}
                onClick={login}
                button_text={t('login.title')}
              />
            )}

            {!is_verified && !is_success && (
              <MessageSection
                alert_type="warning"
                message={t('verify.warning_message')}
                onClick={verifyUser}
                button_text={t('verify.verify_btn')}
              />
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
