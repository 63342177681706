interface ImageProps {
  className?: string;
  src?: string;
  alt?: string;
  onClick?: () => void;
}

const Image = ({ className, src, alt, onClick }: ImageProps) => {
  return <img className={className} src={src} alt={alt} onClick={onClick} />;
};

export default Image;
