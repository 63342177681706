import React from 'react';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="font-bold text-md">{t('terms_dlg.subtitle_1')}</div>
      <div className="mb-4">{t('terms_dlg.text_1')}</div>
      <div className="font-bold text-md">{t('terms_dlg.subtitle_2')}</div>
      <div className="mb-4">{t('terms_dlg.text_2')}</div>
      <div className="font-bold text-md">{t('terms_dlg.subtitle_3')}</div>
      <div className="mb-4">{t('terms_dlg.text_3')}</div>
      <div className="font-bold text-md">{t('terms_dlg.subtitle_4')}</div>
      <div className="mb-4">{t('terms_dlg.text_4')}</div>
      <div className="mb-4">{t('terms_dlg.text_5')}</div>
    </div>
  );
};

export default Terms;
