import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_loading: false,
  show_navbar: true,
  show_spinner: false,
};

export const extraSlice = createSlice({
  name: 'extra',
  initialState,

  reducers: {
    setShowNavBar(state, action) {
      state.show_navbar = action.payload;
    },

    setShowSpinner(state, action) {
      state.show_spinner = action.payload;
    },
    setLoading(state, action) {
      state.is_loading = action.payload;
    },
  },

  extraReducers: {},
});

export const { setShowNavBar, setShowSpinner, setLoading } = extraSlice.actions;

export default extraSlice.reducer;
