import React from 'react';

const sectionHeading = (props: { title: string }) => {
  return (
    <h4 className="h-12 mb-2 mt-2 text-xl md:text-2xl font-bold">
      {props.title}
    </h4>
  );
};
export default sectionHeading;
