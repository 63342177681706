import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as User from 'src/services/user';
import * as Storage from '../services/storage';
import { IToken } from 'src/model/user/token';

const initialState = {
  auth_data: {},
  user_data: {},
  login_error: false,
  is_logged_in: false,
  is_loading: false,
  is_success: false,
  is_verified: false,
  is_logged_out: false,
  is_password_updated: false,
  submission_error: false,
  is_submitted: false,
  process_inprogress: true,
  accountData: [],
  userData: [],
};

export const login = createAsyncThunk(
  'user/login',
  async (payload: { email: string; password: string }) => {
    return await User.login(payload);
  },
);

export const signup = createAsyncThunk(
  'user/signup',
  async (payload: {
    given_name: string;
    surname: string;
    email: string;
    password: string;
  }) => {
    return await User.signup(payload);
  },
);

export const logout = createAsyncThunk('user/logout', async () => {
  return await User.logout();
});

export const getUserVerified = createAsyncThunk(
  'user/verify',
  async (payload: any) => {
    const { validation_token } = payload;
    return await User.getVerified(validation_token);
  },
);

export const requestResetPassword = createAsyncThunk(
  'user/request-reset-password',
  async (payload: { email: string }) => {
    return await User.requestResetPassword(payload);
  },
);

export const resetPassword = createAsyncThunk(
  'user/request-reset-password',
  async (payload: { password: string; reset_key: string }) => {
    return await User.resetPassword(payload);
  },
);

export const updatePassword = createAsyncThunk(
  'user/update-password',
  async (payload: {
    email: string;
    current_password: string;
    new_password: string;
  }) => {
    return await User.updatePassword(payload);
  },
);

export const postAccountData = createAsyncThunk(
  'user/account',
  async (payload: any) => {
    const userid = Storage.getUserId();
    //payload.userId = userid;
    const data = {
      userId: userid,
      feedlotName: payload.feedlot_name,
      feedlotLocation: payload.feedlot_location,
      numberOfCattle: payload.number_of_cattle,
      acceptLocalCattle: payload.accept_local_cattle,
      acceptAustralianCattle: payload.accept_australian_cattle,
    };
    return await User.postAccountData(data);
  },
);
export const getUserAccountData = createAsyncThunk(
  'user/getAccount',
  async () => {
    const userid = Storage.getUserId();
    return await User.getAccountData(userid);
  },
);
export const getUserData = createAsyncThunk('user/user', async () => {
  const userid = Storage.getUserId();
  return await User.getUserData(userid);
});
export const updateAccountData = createAsyncThunk(
  'user/updateAccount',
  async (payload: any) => {
    const userid = Storage.getUserId();
    const account_data = {
      feedlotName: payload.feedlot_name,
      feedlotLocation: payload.feedlot_location,
      numberOfCattle: payload.number_of_cattle,
      acceptLocalCattle: payload.accept_local_cattle,
      acceptAustralianCattle: payload.accept_australian_cattle,
    };
    return await User.updateAccountData(account_data, userid);
  },
);
export const updateUserData = createAsyncThunk(
  'user/updateUser',
  async (payload: any) => {
    const userid = Storage.getUserId();
    return await User.updateUserData(payload, userid);
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setAuthData(state, action) {
      state.auth_data = action.payload;
    },

    setIsSuccess(state, action) {
      state.is_success = action.payload;
    },

    setIsLoggedIn(state, action) {
      state.is_logged_in = action.payload;
    },

    setIsPasswordUpdate(state, action) {
      state.is_password_updated = action.payload;
    },

    setUserLogOut(state) {
      state.auth_data = {};
      state.user_data = {};
      state.is_logged_in = false;
      state.is_success = false;
    },
  },

  extraReducers: {
    [login.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [login.rejected.type]: (state) => {
      state.auth_data = {};
      state.is_loading = false;
      state.is_logged_in = false;
      state.is_success = false;

      Storage.clearToken();
    },
    [login.fulfilled.type]: (state, action) => {
      state.auth_data = action.payload;
      state.is_loading = false;
      state.is_logged_in = true;
      state.is_success = true;

      const token: IToken = action.payload.token;
      Storage.saveToken(token);
    },

    [logout.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_logged_out = false;
    },
    [logout.rejected.type]: (state) => {
      state.auth_data = {};
      state.is_loading = false;
      state.is_logged_in = false;
      state.is_success = false;
      state.is_logged_out = true;
    },
    [logout.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_logged_in = false;
      state.is_success = true;
      state.is_logged_out = true;
    },

    [requestResetPassword.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [requestResetPassword.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [requestResetPassword.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
    },

    [resetPassword.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [resetPassword.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [resetPassword.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
    },

    [updatePassword.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
      state.is_password_updated = false;
    },
    [updatePassword.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.is_password_updated = false;
    },
    [updatePassword.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.is_success = true;
      state.is_password_updated = true;

      const token: IToken = action.payload.token;
      Storage.saveToken(token);
    },

    [signup.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [signup.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
    },
    [signup.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.is_success = true;
      state.user_data = action.payload;
    },

    [getUserVerified.pending.type]: (state) => {
      state.is_loading = true;
      state.is_success = false;
    },
    [getUserVerified.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_verified = false;
      state.is_success = false;
    },
    [getUserVerified.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
      state.is_verified = true;
    },
    [postAccountData.pending.type]: (state) => {
      state.is_loading = true;
      state.submission_error = false;
    },
    [postAccountData.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_success = false;
      state.submission_error = true;
    },
    [postAccountData.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_success = true;
      state.submission_error = false;
    },
    [getUserData.pending.type]: (state) => {
      state.is_loading = true;
    },
    [getUserData.rejected.type]: (state) => {
      state.is_loading = false;
    },
    [getUserData.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      const payload = action.payload;
      state.userData = payload.user;
    },
    [getUserAccountData.pending.type]: (state) => {
      state.is_submitted = false;
      state.is_success = false;
      state.is_loading = true;
      state.process_inprogress = true;
    },
    [getUserAccountData.rejected.type]: (state) => {
      state.is_loading = false;
      state.process_inprogress = false;
    },
    [getUserAccountData.fulfilled.type]: (state, action) => {
      state.is_loading = false;
      state.process_inprogress = false;
      const payload = action.payload;
      state.accountData = payload.account;
    },
    [updateUserData.pending.type]: (state) => {
      state.is_loading = true;
      state.is_submitted = false;
    },
    [updateUserData.rejected.type]: (state) => {
      state.is_loading = false;
      state.is_submitted = true;
      state.submission_error = true;
    },
    [updateUserData.fulfilled.type]: (state) => {
      state.is_loading = false;
      state.is_submitted = true;
      state.submission_error = false;
    },
    [updateAccountData.pending.type]: (state) => {
      state.is_success = false;
      state.is_loading = true;
    },
    [updateAccountData.rejected.type]: (state) => {
      state.is_loading = false;
    },
    [updateAccountData.fulfilled.type]: (state) => {
      state.is_loading = false;
    },
  },
});

export const { setAuthData, setIsSuccess, setIsLoggedIn, setUserLogOut } =
  userSlice.actions;

export default userSlice.reducer;
