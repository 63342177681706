import React, { ReactNode } from 'react';
import Warning from 'src/components/basic/icons/warning';
import Tick from 'src/components/basic/icons/tick';

interface Props {
  children?: ReactNode | ReactNode[] | string;
  type?: string;
}

const Alert = ({ children, type, ...rest }: Props) => {
  return (
    <div className="alert" {...rest}>
      {type == 'tick' && <Tick />}
      {type == 'warning' && <Warning />}
      {children}
    </div>
  );
};

export default Alert;
