import Link from 'src/components/basic/link';
import React from 'react';
import SectionHeading from 'src/components/basic/title/section-heading';
import Image from 'src/components/basic/image';
import { useTranslation } from 'react-i18next';

const ChooseType = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="grid-cols-2 grid md:max-w-4xl ml-auto mr-auto">
        <div className="text-left">
          <SectionHeading title={t('home_page_intro_sub_title')} />
        </div>
      </div>
      <div className="grid-cols-2 grid max-w-4xl ml-auto mr-auto">
        <div className="px-2 md:px-0">
          <SectionHeading title={t('type_heading_1')} />
          <div className="pb-4 px-4">{t('type_content_1')}</div>
          <Link
            className="bg-blue-900 rounded-3xl max-w-xs mt-16 lg:mt-0 ml-auto mr-auto h-48 flex items-center justify-center"
            href="/public-survey">
            <Image
              className="w-24 ml-auto mr-auto"
              src="assets/images/user.png"
              alt="user"
            />
          </Link>
        </div>
        <div className="px-2 md:px-0">
          <SectionHeading title={t('type_heading_2')} />
          <div className="pb-4 px-4">{t('type_content_2')}</div>
          <Link
            className="bg-green-900 rounded-3xl max-w-xs mt-16 lg:mt-0 ml-auto mr-auto h-48 flex items-center justify-center"
            href="/survey-welcome">
            <Image
              className="w-24 ml-auto mr-auto"
              src="assets/images/cow-feed.png"
              alt="user"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChooseType;
