import { IMenuItem } from 'src/model/menu-item';

const config = {
  dataURL: process.env.REACT_APP_DATA_URL,
  surveyURL: process.env.REACT_APP_SURVEY_URL,
  userURL: process.env.REACT_APP_USER_URL,
  networkTimeout: 30000,
};
export default config;

export const color_list = {
  mui_primary: '#15624b',
  mui_primary_opacity_20: '#15624b20',
  mui_secondary: '#958b3e',
  mui_gray: '#6b7280',
  mui_white: '#ffffff',
};

export const public_survey_id = 'faf6a76f-2696-4663-af9e-b264f93e9e75';
export const registered_survey_id = '270e27f8-386d-4c89-9e64-4b4f80f61c28';
export const band_survey_id = '306ff998-9dda-46a3-bdf8-71bf2c77ab4f';

export const PASSWORD_MIN_LEN = 8;
export const specialLetterRegex = /[^a-zA-Z0-9]/;
export const lowercaseRegex = /[a-z]/;
export const uppercaseRegex = /[A-Z]/;
export const digitRegex = /[0-9]/;
export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export const help_desk_url =
  'https://ausvet.atlassian.net/servicedesk/customer/portal/13';

export const menu_items: IMenuItem[] = [
  {
    id: 'manage_profile',
    link: '/account',
  },
  {
    id: 'change_password',
    link: '/change-password',
  },
  {
    id: 'logout',
    link: '/',
  },
];

export const survey_type = {
  survey_type_public: 'survey_type_public',
  survey_type_registered: 'survey_type_registered',
};
