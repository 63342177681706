import React, { useEffect, useState } from 'react';
import SurveyContent from '../components/survey-content';
import { useDispatch, useSelector } from 'react-redux';
import { ISurveySection } from 'src/model/survey/section';
import { ISurveyQuestion } from 'src/model/survey/question';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { createResponse, getResponses, getSurvey } from 'src/store/survey';
import { setSurveyType, updateSelectedSectionId } from 'src/services/util';
import Loader from 'src/components/basic/loader';
import i18n from 'src/i18n';
import { useTranslation } from 'react-i18next';
import {
  help_desk_url,
  public_survey_id,
  survey_type,
} from 'src/services/config';
import PageContainer from 'src/pages/components/page-container';

export default function PublicSurvey() {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { response_list, response, is_response_get } = useSelector(
    (state: any) => state.survey,
  );

  const { survey, selected_section_id, is_loading } = useSelector(
    (state: any) => state.survey,
  );

  const [questionList, setQuestionList] = useState<ISurveyQuestion[]>([]);

  useEffect(() => {
    setSurveyType(survey_type.survey_type_public);
    loadSurveyData();
    loadResponse();

    return () => {
      setSurveyType(undefined);
    };
  }, []);

  useEffect(() => {
    loadSurveyData();
  }, [i18n.language]);

  useEffect(() => {
    if (survey) {
      if (selected_section_id == null) {
        if (survey.sections && survey.sections.length > 0) {
          updateSelectedSectionId(survey.sections[0].id);
        }
      } else {
        const selectedCategory: ISurveySection | undefined =
          survey.sections.find(
            (item: ISurveySection) => item.id === selected_section_id,
          );
        setQuestionList(selectedCategory ? selectedCategory.questions : []);
      }
    }
  }, [survey, selected_section_id]);

  useEffect(() => {
    if (is_response_get) {
      if (response_list.length <= 0) {
        makeNewResponse();
      }
    }
  }, [response_list, response, is_response_get]);

  const loadResponse = () => {
    dispatch(
      getResponses({
        survey_id: public_survey_id,
        user_id: null,
        status: 'DRAFT',
      }),
    );
  };

  const makeNewResponse = () => {
    dispatch(
      createResponse({
        survey_id: public_survey_id,
        user_id: null,
      }),
    );
  };

  const loadSurveyData = () => {
    const payload = {
      survey_id: public_survey_id,
      lang: i18n.language,
      depth: 'question',
    };
    dispatch(getSurvey(payload));
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <div className="mx-auto max-w-screen flex flex-row pt-4 ml-80 md:px-8">
        <div className="basis-5/6"></div>
        <div className="basis-1/6">
          <a
            href={help_desk_url}
            target="_rel"
            className="text-accent link link-hover px-1">
            {t('help_desk')}
          </a>
        </div>
      </div>
      <div className="mx-auto max-w-screen flex flex-row px-4 py-4 md:px-8">
        {survey && (
            <div className="flex-1 overflow-y-auto">
              <SurveyContent question_list={questionList} />
            </div>
        )}
      </div>
    </PageContainer>
  );
}
