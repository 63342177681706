import React, {
  useState,
  useEffect,
  FormEventHandler,
  ChangeEventHandler,
} from 'react';
import QuestionList from './components/questions-list';
import { Form, useFormValidation } from 'reactjs-forms';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { postAccountData, getUserAccountData } from 'src/store/user';
import Loader from 'src/components/basic/loader';
import { toast } from 'react-toastify';

const Account = () => {
  const assessment_modal_id = 'assessment_confirm_modal';
  const navigate = useNavigate();
  const validation = useFormValidation();
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const {
    is_success,
    is_loading,
    submission_error,
    accountData,
    process_inprogress,
    is_logged_in,
  } = useSelector((state: any) => state.user);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [formFields, setFormFields] = useState({
    userId: '',
    feedlot_name: '',
    feedlot_location: '',
    number_of_cattle: '',
    accept_local_cattle: '',
    accept_australian_cattle: '',
  });
  useEffect(() => {
    if (is_logged_in) {
      dispatch(getUserAccountData());
    } else {
      navigate('/login');
    }
  }, []);
  useEffect(() => {
    if (!process_inprogress && !is_success) {
      if (Object.keys(accountData).length > 0) {
        navigate('/profile');
      } else {
        (
          document.getElementById(assessment_modal_id) as HTMLFormElement
        ).showModal();
      }
    }
  }, [accountData, process_inprogress]);
  useEffect(() => {
    if (!is_loading && is_success) {
      toast.success(t('submission_success'));
      navigate('/survey-welcome');
    }
  }, [is_loading, is_success]);
  useEffect(() => {
    if (submission_error) {
      toast.error(t('submission_error'));
    }
  }, [submission_error]);
  const onSubmit: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    const { isValid } = validation();
    if (
      isValid &&
      formFields.feedlot_name &&
      formFields.feedlot_location &&
      formFields.number_of_cattle &&
      formFields.accept_local_cattle &&
      formFields.accept_australian_cattle
    ) {
      dispatch(postAccountData(formFields));
    } else {
      setShowError(true);
    }
  };
  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    setShowError(false);
    switch (id) {
      case 'feedlot_name':
        setFormFields({ ...formFields, feedlot_name: value });
        break;
      case 'feedlot_location':
        setFormFields({ ...formFields, feedlot_location: value });
        break;
      case 'number_of_cattle':
        setFormFields({ ...formFields, number_of_cattle: value });
        break;
      case 'accept_local_cattle':
        setFormFields({ ...formFields, accept_local_cattle: value });
        break;
      case 'accept_australian_cattle':
        setFormFields({ ...formFields, accept_australian_cattle: value });
        break;
    }
  };
  const onClose = () => {
    // Navigate to Home
    navigate('/home');
  };
  return (
    <div className="sm:px-2 lg:px-16">
      {is_loading && <Loader />}
      <div className="flex flex-row my-4 py-2 text-gray-900">
        <dialog
          id={assessment_modal_id}
          className="modal modal-bottom sm:modal-middle">
          <Form
            onSubmit={onSubmit}
            autoComplete="off"
            data-theme="light"
            method="dialog"
            className="modal-box text-gray-700">
            <label className="label">
              <span className="label-text font-bold">
                {t('assessment.heading')}
              </span>
            </label>
            {showError && (
              <p className="text-sm text-red-500 ">{t('required_message')}</p>
            )}
            <QuestionList payload={formFields} onChange={changeHandler} />
            <div className="modal-action">
              <button className="btn btn-primary text-white">
                {t('assessment.submit')}
              </button>
              <button className="btn btn-accent text-white" onClick={onClose}>
                {t('assessment.cancel')}
              </button>
            </div>
          </Form>
        </dialog>
      </div>
    </div>
  );
};
export default Account;
