import React from 'react';
import QuestionList from './question/question-list';
import { ISurveyQuestion } from 'src/model/survey/question';

const SurveyContent = (props: { question_list: ISurveyQuestion[] }) => {
  return (
    <div className="w-full flex-1 overflow-y-auto">
      <div className="px-4 md:pr-8 lg:pr-16">
        <QuestionList question_list={props.question_list} />
      </div>
    </div>
  );
};

export default SurveyContent;
