import React from 'react';
const Footer = () => {
  return (
    <footer
      data-theme="dark"
      className="footer footer-center p-10 bg-base-100 text-base-content rounded">
      <div>
        <p>Copyright © 2023 - All right reserved by Ausvet Ltd</p>
      </div>
    </footer>
  );
};
export default Footer;
