import React, { ReactNode } from 'react';
interface DialogProps {
  modal_id: string;
  is_open: boolean;
  title?: string;
  close_text?: string;
  children?: ReactNode | ReactNode[] | string;
  onClose?: () => void;
}
const ConfirmDialog = ({
  modal_id,
  is_open,
  title,
  close_text,
  children,
  onClose,
}: DialogProps) => {
  return (
    <dialog
      id={modal_id}
      className="modal modal-bottom sm:modal-middle"
      open={is_open}>
      <div data-theme="light" className="modal-box text-gray-700">
        <h3 className="font-bold text-2xl">{title}</h3>
        <p className="py-2">{children}</p>
        <div className="modal-action">
          {close_text && (
            <button className="btn btn-primary text-white" onClick={onClose}>
              {close_text}
            </button>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default ConfirmDialog;
