import React from 'react';

interface FormLabelProps {
  label: string;
  text: string;
}

const FormLabel = ({ label, text }: FormLabelProps) => {
  return (
    <div className="text-sm px-1 my-2">
      <span className="font-bold">{label}: </span>
      <span>{text}</span>
    </div>
  );
};

export default FormLabel;
