import Config from './config';
import * as Storage from 'src/services/storage';
import { store } from '../store';

import axios from 'axios';
import { setUserLoggedIn } from './util';

export async function request(params: any, skipAuthHeader = false) {
  const headers = { ...params.header };

  if (!skipAuthHeader) {
    const token = Storage.readToken();
    headers.Authorization = `Bearer ${token.security_jwt}`;
  }

  const url = params.url?.startsWith('http')
    ? params.url
    : Config.dataURL + params.url;

  try {
    const response = await axios({
      ...params,
      url,
      headers,
      timeout: Config.networkTimeout,
    });

    store.dispatch({
      type: 'error/setErrorMsg',
      payload: [],
    });

    return response;
  } catch (error: any) {
    // Logout on 401 (tokens appear to last forever)
    if (error?.response?.status === 401 || error?.message?.endsWith('401')) {
      Storage.clearToken();
      setUserLoggedIn(false);

      const errors = error?.response.data.response?.description;
      store.dispatch({
        type: 'error/setErrorMsg',
        payload: errors ? (Array.isArray(errors) ? errors : [errors]) : [],
      });
    }

    if (
      error?.response?.status === 422 ||
      error?.response?.status === 400 ||
      error?.response?.status === 403 ||
      error?.response?.status === 404 ||
      error?.response?.status === 203 ||
      error?.response?.status === 204 ||
      error?.response?.status === 209 ||
      error?.response?.status === 500
    ) {
      const errors = error?.response.data?.message;

      store.dispatch({
        type: 'error/setErrorMsg',
        payload: errors ? (Array.isArray(errors) ? errors : [errors]) : [],
      });
    }

    throw error;
  }
}
