import React, { FC } from 'react';
import FormInput from 'src/components/basic/form/form-input';
import { InputProps } from 'reactjs-forms/types';
import FormRadio from './radio';
import { useTranslation } from 'react-i18next';
interface FormFieldsProps extends InputProps {
  payload: any;
}
const QuestionList: FC<FormFieldsProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation();
  return (
    <div {...props}>
      <FormInput
        label={t('assessment.question_1')}
        type={'text'}
        placeholder={t('assessment.question_1')}
        identity="text"
        id="feedlot_name"
        maxLength={255}
        value={props.payload.feedlot_name}
        onChange={onChange}
        validation={{
          required: true,
        }}
      />
      <FormInput
        label={t('assessment.question_2')}
        type={'text'}
        placeholder={t('assessment.question_2')}
        identity="text"
        id="feedlot_location"
        maxLength={255}
        value={props.payload.feedlot_location}
        onChange={onChange}
        validation={{
          required: true,
        }}
      />
      <FormInput
        label={t('assessment.question_3')}
        type={'number'}
        placeholder={t('assessment.question_3')}
        identity="text"
        id="number_of_cattle"
        value={props.payload.number_of_cattle}
        onChange={onChange}
        validation={{
          required: true,
        }}
      />
      <div className="form-control">
        <label className="label">
          <span className="label-text font-bold">
            {t('assessment.question_4')}
          </span>
        </label>
        <div className="flex">
          <FormRadio
            label={t('assessment.radio_no')}
            name="accept_local_cattle"
            id="accept_local_cattle"
            checked={
              (typeof props.payload.accept_local_cattle == 'boolean' &&
                !props.payload.accept_local_cattle) ||
              props.payload.accept_local_cattle == 'no'
            }
            value="no"
            onFocus={onChange}
            onChange={onChange}
          />
          <FormRadio
            label={t('assessment.radio_yes')}
            name="accept_local_cattle"
            id="accept_local_cattle"
            checked={
              (typeof props.payload.accept_local_cattle == 'boolean' &&
                props.payload.accept_local_cattle) ||
              props.payload.accept_local_cattle == 'yes'
            }
            value="yes"
            onFocus={onChange}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="form-control">
        <label className="label">
          <span className="label-text font-bold">
            {t('assessment.question_5')}
          </span>
        </label>
        <div className="flex">
          <FormRadio
            label={t('assessment.radio_no')}
            name="accept_australian_cattle"
            id="accept_australian_cattle"
            checked={
              (typeof props.payload.accept_australian_cattle == 'boolean' &&
                !props.payload.accept_australian_cattle) ||
              props.payload.accept_australian_cattle == 'no'
            }
            value="no"
            onFocus={onChange}
            onChange={onChange}
          />
          <FormRadio
            label={t('assessment.radio_yes')}
            name="accept_australian_cattle"
            id="accept_australian_cattle"
            checked={
              (typeof props.payload.accept_australian_cattle == 'boolean' &&
                props.payload.accept_australian_cattle) ||
              props.payload.accept_australian_cattle == 'yes'
            }
            value="yes"
            onFocus={onChange}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
export default QuestionList;
