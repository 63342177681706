import React from 'react';
import QuestionItem from './question-item';
import QuestionHeader from './question-header';
import { ISurveyQuestion } from 'src/model/survey/question';
import QuestionFooter from './question-footer';

const QuestionList = (props: { question_list: ISurveyQuestion[] }) => {
  const { question_list } = props;

  return (
    <div>
      <QuestionHeader />
      {question_list.length == 0 && <div className="h-64"></div>}
      {[...question_list]
        .sort((a: ISurveyQuestion, b: ISurveyQuestion) => {
          return a.question_number - b.question_number;
        })
        .map((item: ISurveyQuestion) => {
          return (
            <QuestionItem
              key={`question-item-${item.id}`}
              question_item={item}
            />
          );
        })}
      <QuestionFooter />
    </div>
  );
};

export default QuestionList;
