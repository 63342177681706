import React from 'react';
import Image from '../../../components/basic/image';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface LanguageProps {
  code: string;
}

const MenuItem = (props: LanguageProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { code } = props;

  const changeLanguage = (event: any) => {
    event.preventDefault();
    i18n.changeLanguage(code);
    navigate('?lang=' + code);
  };

  return (
    <a className="cursor-pointer" onClick={changeLanguage}>
      <Image className="ml-auto mr-auto" src={`assets/icons/${code}.png`} />
    </a>
  );
};

export default MenuItem;
