import React from 'react';
import Paragraph from 'src/components/basic/paragraph';
import Gap from 'src/components/basic/gap';
import PageSubTitle from 'src/components/basic/title/page-sub-title';
import { useTranslation, Trans } from 'react-i18next';

const RiskAssessment = () => {
  const { t } = useTranslation();
  return (
    <div className="ml-auto mr-auto">
      <PageSubTitle title={t('home_page_intro_title')} />
      <Gap gap="h-2" />
      <Paragraph>
        <Trans i18nKey="home_page_intro_description">
          This site is developed and maintained by
          <a
            href="https://ausvet.com.au/"
            className="text-accent underline"
            target="_rel">
            Ausvet
          </a>
          . The site is intended for use by owners, managers and veterinarians
          working in cattle feedlots in Indonesia and is focused on the risks
          associated with foot and mouth disease (FMD) and lumpy skin disease
          (LSD).
        </Trans>
      </Paragraph>
    </div>
  );
};

export default RiskAssessment;
