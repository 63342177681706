import React from 'react';
import Alert from 'src/components/basic/alert';

interface sectionProps {
  alert_type: string;
  button_text?: string;
  onClick?: () => void;
  message?: string;
}

export default function MessageSection({
  alert_type,
  onClick,
  button_text,
  message,
}: sectionProps) {
  return (
    <div className="w-full">
      <Alert type={alert_type}>{message}</Alert>
      <div className="form-control mt-6">
        <button className="btn btn-accent text-white" onClick={onClick}>
          {button_text}
        </button>
      </div>
    </div>
  );
}
