import React from 'react';
import { menu_items } from 'src/services/config';
import MenuItem from './menu-item';
import { IMenuItem } from 'src/model/menu-item';
import { useTranslation } from 'react-i18next';

const MenuBox = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ul
        className={
          'menu bg-lr-green-1 w-72 md:w-80 rounded-lg font-mono py-2 mr-4 md:mr-8 md:py-4'
        }>
        {menu_items.map((item: IMenuItem) => {
          return (
            <MenuItem
              key={`menu-item-${item.id}`}
              id={item.id}
              title={t(`menu.${item.id}`)}
              active={false}
              menuLink={item.link}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default MenuBox;
