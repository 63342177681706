import React from 'react';
import Container from './container';
import Paragraph from 'src/components/basic/paragraph';
import PageSubTitle from 'src/components/basic/title/page-sub-title';
import { useTranslation, Trans } from 'react-i18next';
import { help_desk_url } from 'src/services/config';
const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="ml-auto mr-auto">
        <div className="text-left">
          <PageSubTitle title={t('about_heading')} />
        </div>
        <Paragraph>
          <Trans i18nKey="about_description">
            This site was created by
            <a
              href="https://ausvet.com.au/"
              className="text-accent underline"
              target="_rel">
              Ausvet
            </a>
            with funding from the Meat & Livestock Australia. Please submit any
            comments, questions or suggestions
            <a
              href={help_desk_url}
              className="text-accent underline"
              target="_rel">
              here
            </a>
            .
          </Trans>
        </Paragraph>
      </div>
    </Container>
  );
};

export default AboutUs;
