import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode | ReactNode[] | string;
}
const paragraph = ({ children, ...rest }: Props) => {
  return (
    <p
      className="font-normal text-left mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400 font-calibri"
      {...rest}>
      {children}
    </p>
  );
};
export default paragraph;
