import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import PageContainer from '../../components/page-container';
import { ExtendedHTMLFormElement } from 'reactjs-forms/types';
import FormTitle from 'src/components/basic/form/form-title';
import FormInput from 'src/components/basic/form/form-input';
import { useTranslation } from 'react-i18next';
import { Form, useFormValidation } from 'reactjs-forms';
import { isValidEmail, setUserSuccess } from 'src/services/util';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { requestResetPassword } from 'src/store/user';
import Loader from 'src/components/basic/loader';

export default function ResetPassword() {
  const { t } = useTranslation();
  const validation = useFormValidation();

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { is_loading, is_success } = useSelector((state: any) => state.user);
  const { error_msg } = useSelector((state: any) => state.error);

  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!is_loading && is_success) {
      toast.success(t('reset-password.success'));
      setUserSuccess(false);
    }
  }, [is_loading, is_success]);

  useEffect(() => {
    error_msg.map((msg: string) => {
      toast.error(msg);
    });
  }, [error_msg]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'email':
        setEmail(value);
        break;
    }
  };

  const submitHandler: FormEventHandler<ExtendedHTMLFormElement> = (e) => {
    e.preventDefault();
    const { isValid } = validation();
    if (isValid && email) {
      dispatch(requestResetPassword({ email }));
    }
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      <Form onSubmit={submitHandler} autoComplete="off">
        <div className="hero min-h-screen bg-base-200">
          <div className="card flex-shrink-0 w-full max-w-md shadow-2xl bg-base-100">
            <div className="card-body">
              <FormTitle title={t('reset-password.title')} />
              <FormInput
                label={t('reset-password.email')}
                type={'email'}
                placeholder={t('reset-password.enter_email')}
                identity="email"
                id="email"
                value={email}
                onChange={changeHandler}
                customValidation={{
                  isValidEmail: isValidEmail(t('reset-password.email'), email),
                }}
              />

              <div className="form-control mt-6">
                <button className="btn btn-error text-white">
                  {t('reset-password.title')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </PageContainer>
  );
}
