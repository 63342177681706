import { IToken } from 'src/model/user/token';
import config from './config';
import { request } from './network';
import { readToken } from './storage';

export async function getVerified(validation_token: string) {
  const response = await request(
    {
      method: 'GET',
      url: `${config.userURL}/v1/users/validate/${validation_token}`,
    },
    true,
  );

  return response.data;
}

export async function login(payload: { email: string; password: string }) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/login`,
      data: payload,
    },
    true,
  );

  return response.data;
}

export async function signup(payload: {
  given_name: string;
  surname: string;
  email: string;
  password: string;
}) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/users`,
      data: payload,
    },
    true,
  );

  return response.data;
}

export async function logout() {
  const token: IToken = readToken();

  const payload = {
    session_jwt: token ? token.session_jwt : '',
  };

  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/logout`,
      data: payload,
    },
    false,
  );

  return response.data;
}

export async function requestResetPassword(payload: { email: string }) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/request-reset-password`,
      data: payload,
    },
    true,
  );

  return response.data;
}

export async function resetPassword(payload: {
  password: string;
  reset_key: string;
}) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/reset-password`,
      data: payload,
    },
    true,
  );

  return response.data;
}

export async function updatePassword(payload: {
  email: string;
  current_password: string;
  new_password: string;
}) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.userURL}/v1/update-password`,
      data: payload,
    },
    true,
  );

  return response.data;
}

export async function postAccountData(payload: {
  userId: string;
  feedlotName: string;
  feedlotLocation: string;
  numberOfCattle: string;
  acceptLocalCattle: string;
  acceptAustralianCattle: string;
}) {
  const response = await request(
    {
      method: 'POST',
      url: `${config.dataURL}/v1/accounts`,
      data: payload,
    },
    false,
  );
  return response.data;
}
export async function getAccountData(userId: string) {
  const response = await request(
    {
      method: 'GET',
      url: `${config.dataURL}/v1/accounts/${userId}`,
    },
    false,
  );
  return response.data;
}
export async function getUserData(userId: string) {
  const response = await request(
    {
      method: 'GET',
      url: `${config.userURL}/v1/users/${userId}`,
    },
    false,
  );
  return response.data;
}
export async function updateAccountData(
  payload: {
    feedlotName: string;
    feedlotLocation: string;
    numberOfCattle: string;
    acceptLocalCattle: string;
    acceptAustralianCattle: string;
  },
  userId: string,
) {
  const response = await request(
    {
      method: 'PUT',
      url: `${config.dataURL}/v1/accounts/${userId}`,
      data: payload,
    },
    false,
  );
  return response.data;
}
export async function updateUserData(payload: any, userId: string) {
  const response = await request(
    {
      method: 'PUT',
      url: `${config.userURL}/v1/users/${userId}`,
      data: payload,
    },
    false,
  );
  return response.data;
}
