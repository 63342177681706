import React from 'react';
import { useSelector } from 'react-redux';
import Image from 'src/components/basic/image';

import { updateSelectedSectionId } from 'src/services/util';

interface SectionItemProps {
  section_id: number;
  title: string;
  icon: string | null;
  active: boolean;
  completed: boolean;
  started: boolean;
}

const SectionItem = (props: SectionItemProps) => {
  const { section_id, title, icon, active, completed, started } = props;

  const onItemClicked = () => {
    updateSelectedSectionId(section_id);
  };

  const sectionCompleted = () => {
    return completed ? '' : 'hidden';
  };

  const { highlight_unanswered } = useSelector(
    (state: any) => state.survey,
  );
  
  const sectionStarted = () => {
    return (!completed && (started || highlight_unanswered)) ? 'bold text-sm text-red-500' : 'hidden';
  };

  return (
    <li onClick={onItemClicked}>
      <a
        className={`text-white ${
          active ? 'bg-secondary' : ''
        } hover:bg-lr-green-2 hover:text-white`}>
        <Image
          className="w-7 mr-2 rounded-full"
          src={`assets/icons/${icon ?? 'no.png'}`}
        />
        <span className="line-clamp-4 font-calibri">{title}</span>
        <span className={`${sectionCompleted()}`}>&#10003;</span>
        <span className={`${sectionStarted()}`}><b>X</b></span>
      </a>
    </li>
  );
};

export default SectionItem;
