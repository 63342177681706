import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LoginSignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onLogin = () => {
    navigate('/login');
  };

  const onSignUp = () => {
    navigate('/signup');
  };

  return (
    <div className="grid grid-cols-2 gap-2 ml-2">
      <button className="btn btn-sm btn-accent text-white" onClick={onLogin}>
        {t('login.title')}
      </button>
      <button className="btn btn-sm btn-primary text-white" onClick={onSignUp}>
        {t('signup.title')}
      </button>
    </div>
  );
};

export default LoginSignUp;
