import React from 'react';
import Container from './components/container';
import AboutUs from './components/about-us';
import ChooseType from './components/choose-type';
import RiskAssessment from './components/risk-assessment';

export default function HomeContent() {
  return (
    <div className="content-center px-8 md:px-16 lg:px-24">
      <div className="text-center">
        <Container>
          <RiskAssessment />
          <ChooseType />
        </Container>
        <AboutUs />
      </div>
    </div>
  );
}
