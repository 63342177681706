import React from 'react';

import HomeContent from './home-content';
import PageContainer from '../components/page-container';

export default function Home() {
  return (
    <PageContainer>
      <div className="mx-auto max-w-screen">
        <HomeContent />
      </div>
    </PageContainer>
  );
}
