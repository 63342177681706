import React, { useEffect, useState } from 'react';
import Loader from 'src/components/basic/loader';

import PageContainer from 'src/pages/components/page-container';
import { registered_survey_id, survey_type } from 'src/services/config';
import SurveyContent from '../components/survey-content';
import { ISurveySection } from 'src/model/survey/section';
import { getSurvey } from 'src/store/survey';
import { ISurveyQuestion } from 'src/model/survey/question';
import i18n from 'src/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setSurveyType, updateSelectedSectionId } from 'src/services/util';
import { useNavigate } from 'react-router-dom';
export default function RegisteredSurvey() {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const navigate = useNavigate();
  const { survey, selected_section_id, is_loading } = useSelector(
    (state: any) => state.survey,
  );

  const [questionList, setQuestionList] = useState<ISurveyQuestion[]>([]);
  const { response } = useSelector((state: any) => state.survey);

  useEffect(() => {
    setSurveyType(survey_type.survey_type_registered);
    loadSurveyData();
    return () => {
      setSurveyType(undefined);
    };
  }, []);

  useEffect(() => {
    if (!response) {
      goSurveyWelcome();
    }
  }, [response]);

  useEffect(() => {
    loadSurveyData();
  }, [i18n.language]);

  useEffect(() => {
    if (survey) {
      if (!selected_section_id) {
        if (survey.sections && survey.sections.length > 0) {
          updateSelectedSectionId(survey.sections[0].id);
        }
      } else {
        const selectedCategory: ISurveySection | undefined =
          survey.sections.find(
            (item: ISurveySection) => item.id === selected_section_id,
          );
        setQuestionList(selectedCategory ? selectedCategory.questions : []);
      }
    }
  }, [survey, selected_section_id]);

  const loadSurveyData = () => {
    const payload = {
      survey_id: registered_survey_id,
      lang: i18n.language,
      depth: 'question',
    };
    dispatch(getSurvey(payload));
  };

  const goSurveyWelcome = () => {
    navigate('/survey-welcome');
  };

  return (
    <PageContainer>
      {is_loading && <Loader />}
      {survey && <SurveyContent question_list={questionList} />}
    </PageContainer>
  );
}
