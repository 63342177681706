import translationEN from '../locale/en/translation.json';
import translationID from '../locale/id/translation.json';

export const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
};

export const langs = {
  en: { nativeName: 'English' },
  id: { nativeName: 'Indonesian' },
};
